.notMobile {
  display: unset!important;
}
.inMobile {
  display: none!important;
}
.paragraph {
  margin-top: 0!important;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.custom-control-label {
  cursor: pointer;
}
table.max-content-always {
  width: max-content!important;
}
.fixe-height-modal > .modal-content {
  max-height: 48em;
  min-height: 40em;
}
.subtable:hover {
  background-color: transparent!important;
}
.subtable > td {
  background-color: rgba(233,236,239,.5)!important;
}
table.fixed thead th {
  position: sticky;
  z-index: 1;
  background: white;
}
table.fixed td {
  background: #fff;
  padding: 4px 5px;
}
table.fixed thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
table.fixed tbody td:first-child, table.fixed tbody th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
table.fixed tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
table.fixed tbody td {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  min-width: 100px;
}
.btn-opacity {
  opacity: .7;
}

.btn-opacity:hover{
  opacity: 1;
}

.tox-tinymce {
  z-index: 0;
}

.zi-8 {
  z-index: 8!important;
}

.zi-8.__menu {
  z-index: 9!important;
}

.app-footer {
  z-index: 10!important;
}

div.react-trello-board > div, div.react-trello-board > div > div.smooth-dnd-container, div.react-trello-board > div > div.smooth-dnd-container > section.react-trello-lane , div.react-trello-board div.smooth-dnd-container.vertical {
  height: 100%;
}

@media (max-width: 992px) {
  .notMobile {
    display: none!important;
  }
  .inMobile {
    display: unset!important;
  }
  ul#navAdaptive {
    display: grid!important;
  }
  table.max-content {
    width: max-content!important;
  }
  .fixe-height-modal > .modal-content {
    max-height: 45em;
    min-height: 40em;
  }
}
